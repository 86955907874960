import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { de, en, es, fr, it, he, pt, ru } from 'vuetify/lib/locale'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#58105a',
        secondary: '#4caf50',
        tertiary: '#495057',
        accent: '#82B1FF',
        error: '#f55a4e',
        info: '#33b5e5',
        success: '#5cb860',
        warning: '#ffa21a',
      },
    },
  },
  icons: {
    iconfont: 'mdi',
    values: {
      sort: 'mdi-chevron-down',
      expand: 'mdi-menu-down',
    },
  },
  lang: {
    locales: {
      de, en, es, fr, it, he, pt, ru,
    },
  },
})
